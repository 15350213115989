/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getUpStreamsList,
  postUpStreams,
  upStreamActions,
  editMWrapper,
} from "_store/upStream.slice";
import StreamCheckList from "./StreamCheckList";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import InnerLoader from "_components/InnerLoader";
import { createAlert } from "_store/alert.slice";
import { useNavigate } from "react-router-dom";
import { getFlattenData, toggleCheckAll } from "./upstream.util";

const UpdateUpStreamModal = () => {
  const {
    isOpen,
    id,
    title = "",
    getUpstreamUrl,
    submitUrl,
    successRedirectUrl,
    formData = {},
    submitCallback,
    upStreamsList = [],
    isLoadingFetch,
    wrapperUrl,
    state,
    samePagerender
  } = useSelector((state) => state.upStream);
  const [flattenUpStream, setFlattenUpStream] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const fetchUpStreamList = async () => {
    await dispatch(
      getUpStreamsList({
        url: getUpstreamUrl,
        data: {
          idList: [id],
        },
      })
    );
  };

  useEffect(() => setStep(1), [isOpen]);

  useEffect(() => {
    if (step === 2 && isOpen && getUpstreamUrl && upStreamsList?.length === 0) {
      fetchUpStreamList();
    }
  }, [step]);

  useEffect(() => {
    if (!wrapperUrl && isOpen) {
      fetchUpStreamList();
      setStep(2);
    }
  }, [wrapperUrl, isOpen]);

  const handleClose = (_event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    dispatch(upStreamActions.resetUpStreamModal());
    if (typeof submitCallback === "function") {
      submitCallback("cancel");
    }
  };

  const handleSubmit = async () => {
    const updatedData = {
      [id]: {
        data: { ...formData, id },
        upstreamsToBeUpdated: flattenUpStream,
      },
    };

    setLoader(true);
    const { payload: result } = await dispatch(
      postUpStreams({
        url: submitUrl,
        data: updatedData,
      })
    );

    if (result) {
      handleClose();
      dispatch(
        createAlert({
          alertType: "success",
          msg: "Updated successfully",
          timeout: 5000,
        })
      );
      if (state && successRedirectUrl) {
        navigate(successRedirectUrl, state);
      }
      else if (successRedirectUrl) {
        navigate(successRedirectUrl);
      }
    }
    setLoader(false);
  };
  const handleUpdateAllReferences = async () => {
    const updatedData = {
      [id]: {
        data: { ...formData, id },
        upstreamsToBeUpdated: {},
      },
    };

    setLoader("all_ref");
    const { payload: result } = await dispatch(
      postUpStreams({
        url: submitUrl,
        data: updatedData,
      })
    );

    if (result) {
      handleClose();
      dispatch(
        createAlert({
          alertType: "success",
          msg: "Updated successfully",
          timeout: 5000,
        })
      );
      if (state && successRedirectUrl) {
        if (samePagerender) {
          const parts = successRedirectUrl.split("/");
          parts[4] = result?.newRecord?.id; // Update the ID dynamically
          const updatedRedirectUrl = parts.join("/");
          navigate(updatedRedirectUrl, state);
        } else {
          navigate(successRedirectUrl, state);
        }
      }
      else if (successRedirectUrl) {
        navigate(successRedirectUrl);
      }
    }
    setLoader(false);
  };

  const handleCheckAll = () => {
    const updateList = toggleCheckAll(
      JSON.parse(JSON.stringify(upStreamsList)),
      true
    );
    setFlattenUpStream(getFlattenData(updateList));
    dispatch(upStreamActions.setUpStreamList(updateList));
  };

  const handleUncheckAll = () => {
    const updateList = toggleCheckAll(
      JSON.parse(JSON.stringify(upStreamsList)),
      false
    );
    setFlattenUpStream(getFlattenData(updateList));
    dispatch(upStreamActions.setUpStreamList(updateList));
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={isOpen}
      onClose={handleClose}
    >
      {isLoadingFetch && <InnerLoader isBlockLoader={true} />}
      <DialogTitle>{title}</DialogTitle>
      {
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: -4,
            zIndex: 9,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      }
      {step === 2 ? (
        <DialogContent>
          <DialogContentText>
            <StreamCheckList
              checkListData={upStreamsList}
              onSetFlattenUpStream={setFlattenUpStream}
            />
            {!upStreamsList.length && !isLoadingFetch && (
              <Typography variant="h5" textAlign="center">
                No detail found
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {upStreamsList.length && !isLoadingFetch && step === 2 ? (
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                variant="outlined"
                color={"primary"}
                size="small"
                onClick={handleCheckAll}
                disabled={loader}
                style={{ marginLeft: "0" }}
              >
                Check all
              </Button>
              <Button
                variant="outlined"
                color={"primary"}
                onClick={handleUncheckAll}
                size="small"
                disabled={loader}
                style={{ marginLeft: "5px" }}
              >
                Uncheck all
              </Button>
            </Box>
          ) : null}
          {step === 1 && (
            <>
              {/* <Box
                sx={{
                  m: 1,
                  position: "relative",
                  opacity: 0.7,
                  ":hover": {
                    opacity: 1,
                  },
                }}
              >
                <Button
                  onClick={handleUpdateAll}
                  variant="contained"
                  color={"primary"}
                  disabled={loader}
                >
                  Update This Only
                </Button>
                {loader === "this_only" && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box> */}
              {/* <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  onClick={() => setStep(2)}
                  variant="contained"
                  color={"primary"}
                  disabled={loader}
                >
                  Choose references
                </Button>
              </Box> */}
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyItems: step === 1 ? "center" : "inharrit",
          }}
        >
          {step === 2 ? (
            <>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  onClick={() =>
                    step === 2 && wrapperUrl ? setStep(1) : handleClose()
                  }
                  disabled={loader}
                >
                  {step === 2 && wrapperUrl ? "Back" : "Close"}
                </Button>
              </Box>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color={"primary"}
                  disabled={loader || isLoadingFetch || !upStreamsList.length}
                >
                  Update
                </Button>
                {loader && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  onClick={handleUpdateAllReferences}
                  variant="contained"
                  color={"primary"}
                  disabled={loader}
                >
                  Update All References
                </Button>
                {loader === "all_ref" && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUpStreamModal;
