/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

//COmponents
import { NavLink } from "react-router-dom";

//Store
import { logout, setLocalUser } from "_store/auth.slice";
import Breadcrumbs from "./Breadcrumbs";
import { getUserById } from "_store/access.slice";
import { createAlert } from "_store/alert.slice";

const Header = ({ navbarOpen, setNavbarOpen }) => {
  const authUser = useSelector((x) => x.auth.user);
  const dispatch = useDispatch();
  const logoutUser = () => dispatch(logout());

  useEffect(()=> {
    const userToken = localStorage.getItem('userToken');
    const now = Date.now() / 1000;
    const expiry = authUser?.exp;
    if(authUser?.exp && userToken && now < expiry){
      dispatch(getUserById(authUser.id)).then((response) => {
        let filtereddata = response?.payload?.data?.[0];
        dispatch(setLocalUser(filtereddata))
      });
    }
    else if (now > expiry){
      dispatch(
        createAlert({
          alertType: "error",
          msg: "Token expired. Kindly try again in sometime.",
          timeout: 5000,
        })
      );
      logoutUser();
    }
  }, [authUser?.exp])

  // only show nav when logged in
  if (!authUser) return null;

  return (
    <header>
      <nav className="navbar navbar-dark bg-dark">
        <NavLink
          to="/dashboard"
          onClick={() => setNavbarOpen(false)}
          className="navbar-brand"
        >
          <img
            src="/assets/images/neve-jewels-group-logo.png"
            alt="Neve Jewels Group"
            className="logo-img"
          />
        </NavLink>
        <Breadcrumbs />

        {authUser ? (
          <div className="float-right" id="profileBox">
            { (authUser?.firstname || authUser?.lastname) && <Tooltip title={authUser?.firstname + " " + authUser?.lastname}>
              <div className="profile-initials">
                {authUser?.firstname?.[0] + authUser?.lastname?.[0]}
              </div>
              </Tooltip>
            } 
            <button className="btn btn-link" onClick={logoutUser}>
              <span className="material-icons">logout</span>
            </button>
          </div>
        ) : (
          ""
        )}
      </nav>
    </header>
  );
};

export default Header;
